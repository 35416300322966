<template>
  <div>
    <PermitHistoryStarter />
  </div>
</template>

<script>
import PermitHistoryStarter from '@/components/ui/dashboard/PermitHistoryStarter.vue'
export default {
  components: {
    PermitHistoryStarter
  }
}
</script>