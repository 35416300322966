<template>
  <v-card style="min-height: 236px;">
    <div class="pa-3">
      <div class="d-flex align-center">
        <div>
          <span class="font-weight-bold text-body-2 primary--text">{{ name }}</span>
          <v-spacer></v-spacer>
          <div class="text-caption">
            Business Line: {{ businessline }} <br/>
            Description: {{ description }} <br/>
            Category: {{ category }} <br/>
            Currency: {{ currency }} <br/>
            Amount: {{ amount }} <br/>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="d-flex">
      <v-btn
        v-if="phone"
        class="flex-grow-1"
        tile
        height="48"
        text
        :href="phone"
      >
        <v-icon left>mdi-phone-outline</v-icon>
        Call
      </v-btn>
      <v-divider v-if="phone && email" vertical></v-divider>
      <v-btn
        v-if="email"
        class="flex-grow-1"
        tile
        height="48"
        text
        :href="email"
      >
        <v-icon left>mdi-email-outline</v-icon>
        Email
      </v-btn>
    </div> -->
    <v-card-actions>
      <v-btn
        color="primary"
        text
        v-show="generateinvoice"
      >
        GENERATE INVOICE
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import router from '../../../../router'

export default {
  props: {
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    businessline: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    category: {
      type: String,
      default: ''
    },
    currency: {
      type: String,
      default: ''
    },
    amount: {
      type: String,
      default: ''
    },
    generateinvoice: {
      type: Boolean
    }
  },
  methods: {
    ViewAsset: (link) => {
      router.push({ path: link })
      console.log('the console is ', link)
    }
  }
}
</script>
