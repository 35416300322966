<template>
  <v-layout class="fill-height">
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      width="260"
      class="elevation-1"
      :right="$vuetify.rtl"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="d-flex pa-2 align-center">
          <img src="/images/v-logo-small1.png" height="48" alt="logo" class="mr-1 brand-logo">
          <div>
            <!-- <div class="title font-weight-bold text-uppercase primary--text">{{ config.product.name }}</div>
            <div class="overline grey--text">{{ config.product.version }}</div> -->
          </div>
        </div>
      </template>

      <!-- Navigation menu -->
    <main-menu :menu="menu" />
    </v-navigation-drawer>
    <!-- <v-system-bar 
      app
      window
      class="system-bar"
    >
      <v-row>
        <v-col md="6" xs="3" class="text-right grey">
          <div>
            &nbsp;
          </div>
          <span class="current-bus-line">Line Of Business:</span>
        </v-col>
        <v-col md="6" xs="9">
          <v-select
            return-object
            v-model="businessLineId"
            :items="items"
            item-text="name"
            item-value="id"
            label="Switch to another Business Line"
            required
          ></v-select>
        </v-col>
      </v-row>
    </v-system-bar> -->
    <!-- Toolbar -->
    <v-app-bar
      app
      flat
      color="surface"
    >
      <v-card class="flex-grow-1 d-flex pa-1 mt-3 mx-1 secondary">
        <div class="d-flex flex-grow-1 align-center">

          <!-- search input mobile -->
          <!-- <v-text-field
            v-if="showSearch"
            append-icon="mdi-close"
            placeholder="Search"
            prepend-inner-icon="mdi-magnify"
            hide-details
            solo
            flat
            autofocus
            @click:append="showSearch = false"
          ></v-text-field> -->
          <v-select
            v-if="showSearch"
            v-model="select"
            append-icon="mdi-close"
            :items="items"
            :rules="[v => !!v || 'Item is required']"
            label="Item"
            required
          ></v-select>

          <div v-else class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon class="d-lg-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

            <!-- search input desktop -->
            <!-- <v-text-field
              ref="search"
              class="hidden-xs-only"
              placeholder="Search"
              prepend-inner-icon="mdi-magnify"
              hide-details
              rounded
            ></v-text-field> -->
            <div class="hidden-xs-only flex">
              <h2>
                Import / Export Portal
              </h2>
            </div>

            <v-spacer class="d-block d-sm-none"></v-spacer>

            <v-btn class="d-block d-sm-none" icon @click="showSearch = true">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>

            <div :class="[$vuetify.rtl ? 'ml-1' : 'mr-1']">
              <toolbar-notifications />
            </div>

            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-container fluid class="pt-3">
      <router-view class="py-2 fill-height"></router-view>

      <!-- DEMO PURPOSES DEFAULT ROUTER VIEW -->
      <div v-if="isDemo" class="py-2 fill-height">
        <slot></slot>
      </div>

    </v-container>

    <!-- <v-footer app inset>
      <div class="overline">
        Copyright © 2020 <a href="https://nfmp.gov.ng" target="_blank">NFMP</a>, All rights Reserved
      </div>
      <v-spacer></v-spacer>
      <div class="overline">
        Made with by <a href="https://nfmp.gov.ng" target="_blank">NFMP</a>
      </div>
    </v-footer> -->
  </v-layout>
</template>

<script>
import config from '@/configs'
import MainMenu from '@/components/navigation/MainMenu'
import ToolbarUser from '@/components/toolbar/ToolbarUser'
import ToolbarNotifications from '@/components/toolbar/ToolbarNotifications'
import myServices from '../../../../services/myservices'
// Demo menu content
import menu from './menu'
//import mainmembermenu from './mainmembermenu'
//import firstrunmenu from './firstrunmenu'
//import menu3 from './menu3'

export default {
  components: {
    MainMenu,
    ToolbarUser,
    ToolbarNotifications
  },
  props: {
    isDemo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      menu,
      config,
      drawer: null,
      showSearch: false,
      businessLineId: 0,
      select: null,
      items:[
        {
          'id': 1,
          'name': 'Fertilizer Blender'
        },
        {
          'id': 2,
          'name': 'Fertilizer Distributor'
        },
        {
          'id': 3,
          'name': 'Fertilizer Manufacturer'
        },
        {
          'id': 4,
          'name': 'Fertilizer Importer/Exporter'
        },
        {
          'id': 5,
          'name': 'Create New Business Line'
        }
      ]
    }
  },
  created() {
    this.businessLineId = {
      'id': 1,
      'name': 'Fertilizer Blender'
    }
  },
  mounted() {
    //
  },
  watch: {
    businessLineId: {
      handler(newvalue) {
        //////console.log('item changed', newvalue)
        //////console.log('state id is ', this.stateId)
        //this.statelgas = this.alllga.filter((lga) => lga.state_id === this.stateId.id)
        //////console.log('all the lga is ', this.statelgas)
        console.log('this is the new value ', newvalue)
        /* if (myServices.userFirstrun()) {
          this.menu = this.firstrunmenu
          this.our_portal_name = 'Firstrun'
        }

        if (myServices.userMainmember()) {
          this.menu = this.mainmembermenu
          this.our_portal_name = 'Main Member'
        } */
        /* if (newvalue.id === 1) {
          this.menu = this.mainmembermenu
        }
        if (newvalue.id === 2) {
          this.menu = this.firstrunmenu
        }
        if (newvalue.id === 3) {
          this.menu = this.menu3
        } */
      },
      immediate: true
    }
  }
}
</script>
<style scoped>

.current-bus-line{
  text-align: right !important;
  font-size: 16px !important;
  font-weight: bold !important;
  color: white !important;
}
.system-bar{
  background-color: #8b8988 !important;
}
.system-bar select{
  background-color: white !important;
}
.elevation-1 {
  background-color: #5674ce5d !important;
}
.app-title{
  font-size: 30px;
}

.brand-logo{
  width: 240px !important;
  height: 30px !important;
}

.top-switch{
  background-color: black !important;
}

h1, h2, h3, h4, h5, h6, .text-h4, .text-h1, .text-h2, .text-h3, .text-h4, .text-h5, .text-h6  {
  font-weight: 400 !important;
}
</style>