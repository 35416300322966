<template>
  <Layout1 is-demo>
    <h1 class="text-h4">Permit History</h1>
    <!-- <AppCard1Starter class="pa-0 py-2" /> -->
    <!-- <AppCard2Starter class="pa-0 py-2" /> -->
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col sm="12" md="12">
        <v-card class="d-card-height">
          <v-card-text>
            <v-row>
              <v-col
                cols="12"
              >
                <v-data-table
                  :headers="headers"
                  :items="projects"
                  :search="searchQuery"
                  class="flex-grow-1"
                >
                  <template v-slot:item.permitnos="{ item }">
                    {{item.permitnos}}
                  </template>
                  <template v-slot:item.description="{ item }">
                    {{item.description}}
                  </template>
                  <template v-slot:item.applicationdate="{ item }">
                    {{item.applicationdate}}
                  </template>
                  <template v-slot:item.applicationstatus="{ item }">
                    {{item.applicationstatus}}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <AppCard3Starter class="pa-0 mb-1" />
    <AppCard6Starter class="pa-0 mb-2" /> -->
    <!-- <UserTable class="mb-2" /> -->
  </Layout1>
</template>

<script>
/* import UserTable from '@/components/examples/UserTable' */
import Layout1 from '@/components/ui/application/layout/Layout1' 
//import AppCard1Starter from '@/components/ui/application/appcard/AppCard1Starter'
//import AppCard2Starter from '@/components/ui/application/appcard/AppCard2Starter'/*
//import AppCard3Starter from '@/components/ui/application/appcard/AppCard3Starter'
//import AppCard6Starter from '@/components/ui/application/appcard/AppCard6Starter'

export default {
  components: {
    /* UserTable, */
    Layout1
    //AppCard2Starter,
    //AppCard1Starter
    /* ,
    AppCard3Starter,
    AppCard6Starter */
  },
  data () {
    return {

      headers: [
        { text: 'Permit Nos.', value: 'permitnos' },
        { text: 'Description', value: 'description' },
        { text: 'Application Date', value: 'applicationdate' },
        { text: 'Application Status', value: 'applicationstatus' } 
      ],
      projects: [
        {
          description: 'Import of ABC Items',
          permitnos:'NFMP/Export/003',
          applicationdate:'2/12/2022',
          applicationstatus: 'PENDING'
        }
      ]
    }
  },
  methods: {
    addTableRow: function () {
      const new_row = {
        description: '',
        hscodes:'',
        detailsofexporter:'',
        countryofexport: '',
        packsize: '',
        quantityapproved: ''
      }

      this.projects.push(new_row)
    }
  }
}
</script>

<style scoped>
.card-header-text{
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height{
  min-height: 200px !important;
}

.our-v-table tr{
  border: 1px #000 !important;
}
</style>