<template>
  <AssetsAndProducts />
</template>

<script>
import AssetsAndProducts from '@/components/ui/assetsandproducts/AssetsAndProducts.vue'

export default {
  components: {
    AssetsAndProducts
  }
}
</script>
