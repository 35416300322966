<template>
  <div>
    <GenerateInvoiceStarter />
  </div>
</template>

<script>
import GenerateInvoiceStarter from '@/components/ui/generateinvoice/GenerateInvoiceStarter.vue'
export default {
  components: {
    GenerateInvoiceStarter
  }
}
</script>