<template>
  <GenerateInvoice />
</template>

<script>
import GenerateInvoice from '@/components/ui/generateinvoice/GenerateInvoice.vue'

export default {
  components: {
    GenerateInvoice
  }
}
</script>
