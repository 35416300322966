<template>
  <Layout1 is-demo>
    <h1 class="text-h4">Assets and Products</h1>
    <AssetsCardStarter class="pa-0 py-2" />
    <!-- <AppCard2Starter class="pa-0 py-2" /> -->
    
    <!-- <AppCard3Starter class="pa-0 mb-1" />
    <AppCard6Starter class="pa-0 mb-2" /> -->
    <!-- <UserTable class="mb-2" /> -->
  </Layout1>
</template>

<script>
/* import UserTable from '@/components/examples/UserTable' */
import Layout1 from '@/components/ui/application/layout/Layout1' 
import AssetsCardStarter from '@/components/ui/application/appcard/AssetsCardStarter'
//import AppCard2Starter from '@/components/ui/application/appcard/AppCard2Starter'/*
//import AppCard3Starter from '@/components/ui/application/appcard/AppCard3Starter'
//import AppCard6Starter from '@/components/ui/application/appcard/AppCard6Starter'

export default {
  components: {
    /* UserTable, */
    Layout1,
    //AppCard2Starter,
    AssetsCardStarter/* ,
    AppCard3Starter,
    AppCard6Starter */
  },
  data () {
    return {
      desserts: [
        {
          name: 'Processing Fee',
          fees: 'N300,000.00'
        },
        {
          name: 'Registration Fee',
          fees: '	N1,000,000.00'
        },
        {
          name: 'Renewal Fee',
          fees: 'N500,000.00'
        },
        {
          name: 'Late Application for renewal fee',
          fees: 'N150,000.00'
        },
        {
          name: 'Inspection fee per metric ton',
          fees: 'N100.00'
        },
        {
          name: 'Late submission of tonnage report fee',
          fees: 'N300,000.00'
        }
      ],
      headers: [
        {
          text: 'Payment Type',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Fees', value: 'fees' }
      ]
    }
  }
}
</script>

<style scoped>
.card-header-text{
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height{
  min-height: 300px !important;
}
</style>
