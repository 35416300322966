<template>
  <Transactionhistory />
</template>

<script>
import Transactionhistory from '@/components/ui/transactionhistory/Transactionhistory.vue'

export default {
  components: {
    Transactionhistory
  }
}
</script>
