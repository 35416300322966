<template>
  <Layout1 is-demo>
    <h1 class="text-h4">Apply for Permit</h1>
    <!-- <AppCard1Starter class="pa-0 py-2" /> -->
    <!-- <AppCard2Starter class="pa-0 py-2" /> -->
    <v-divider class="my-2"></v-divider>
    <v-row>
      <v-col sm="12" md="12">
        <v-card class="d-card-height">
          <v-card-text>
            <v-form v-model="valid">
              <v-container>
                <div class="mb-5 font-weight-bold">
                  Basic Information
                  <v-divider></v-divider>
                </div>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="firstname"
                      label="Address of Applicant"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="email"
                      label="Company Tax Identification Number"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="lastname"
                      label="Country of Origin"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="firstname"
                      label="Country of Export (if different from origin):"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="email"
                      label="Mode of Transport"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="lastname"
                      label="Port of entry"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="firstname"
                      label="Approximate Date of Arrival of shipment"
                      required
                    ></v-text-field>
                  </v-col>

                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="email"
                      label="Exact location of final discharge/ Destination/Warehouse address:"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <v-text-field
                      v-model="lastname"
                      label="Port of entry"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <div class="my-5 font-weight-bold">
                  Items to Import
                  <v-divider></v-divider>
                </div>
                <v-row>
                  <v-col
                    cols="12"
                  >
                  <v-data-table
                    :headers="headers"
                    :items="projects"
                    :search="searchQuery"
                    class="flex-grow-1"
                  >
                    <template v-slot:item.description="{ item }">
                      <v-text-field v-model="item.description"></v-text-field>
                    </template>
                    <template v-slot:item.hscodes="{ item }">
                      <v-text-field v-model="item.hscodes"></v-text-field>
                    </template>
                    <template v-slot:item.detailsofexporter="{ item }">
                      <v-text-field v-model="item.detailsofexporter"></v-text-field>
                    </template>
                    <template v-slot:item.countryofexport="{ item }">
                      <v-text-field v-model="item.countryofexport"></v-text-field>
                    </template>
                    <template v-slot:item.packsize="{ item }">
                      <v-text-field v-model="item.packsize"></v-text-field>
                    </template>
                    <template v-slot:item.quantityapproved="{ item }">
                      <v-text-field v-model="item.quantityapproved"></v-text-field>
                    </template>
                  </v-data-table>
                  <v-btn
                    small
                    color="secondary"
                    class="right-align black--text"
                    @click='addTableRow()' 
                  >
                    <v-icon>mdi-plus</v-icon>Add New Item row
                  </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="text-right">
                    <v-btn
                      class="primary"
                    >
                      Apply for Permit
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <AppCard3Starter class="pa-0 mb-1" />
    <AppCard6Starter class="pa-0 mb-2" /> -->
    <!-- <UserTable class="mb-2" /> -->
  </Layout1>
</template>

<script>
/* import UserTable from '@/components/examples/UserTable' */
import Layout1 from '@/components/ui/application/layout/Layout1' 
//import AppCard1Starter from '@/components/ui/application/appcard/AppCard1Starter'
//import AppCard2Starter from '@/components/ui/application/appcard/AppCard2Starter'/*
//import AppCard3Starter from '@/components/ui/application/appcard/AppCard3Starter'
//import AppCard6Starter from '@/components/ui/application/appcard/AppCard6Starter'

export default {
  components: {
    /* UserTable, */
    Layout1
    //AppCard2Starter,
    //AppCard1Starter
    /* ,
    AppCard3Starter,
    AppCard6Starter */
  },
  data () {
    return {

      headers: [
        { text: 'Description', value: 'description' },
        { text: 'HS Codes', value: 'hscodes' },
        { text: 'Details of Exporter', value: 'detailsofexporter' },
        { text: 'Country of Export', value: 'countryofexport' },
        { text: 'Pack Size', value: 'packsize' },
        { text: 'Quantity Approved', value: 'quantityapproved' } 
      ],
      projects: [
        {
          description: '',
          hscodes:'',
          detailsofexporter:'',
          countryofexport: '',
          packsize: '',
          quantityapproved: ''
        }
      ]
    }
  },
  methods: {
    addTableRow: function () {
      const new_row = {
        description: '',
        hscodes:'',
        detailsofexporter:'',
        countryofexport: '',
        packsize: '',
        quantityapproved: ''
      }

      this.projects.push(new_row)
    }
  }
}
</script>

<style scoped>
.card-header-text{
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height{
  min-height: 200px !important;
}

.our-v-table tr{
  border: 1px #000 !important;
}
</style>