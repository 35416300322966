<template>
  <div>
    <WarehousesStarter />
  </div>
</template>

<script>
import WarehousesStarter from '@/components/ui/warehouses/WarehousesStarter.vue'
export default {
  components: {
    WarehousesStarter
  }
}
</script>