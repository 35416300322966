<template>
  <Layout1 is-demo>
    <h1 class="text-h4">Transaction History</h1>
    <!-- <AppCard2Starter class="pa-0 py-2" /> -->
    <v-divider class="my-2"></v-divider>
    <v-row justify="center">
    </v-row>
    <v-row>
      <v-col sm="12" md="12">
        <v-card class="d-card-height pt-2">
          <v-card-text>
            <div class="text--primary mt-5">
              <v-data-table
                dense
                :headers="headers"
                :items="desserts"
                item-key="name"
                class="elevation-1"
              ></v-data-table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- <AppCard3Starter class="pa-0 mb-1" />
    <AppCard6Starter class="pa-0 mb-2" /> -->
    <!-- <UserTable class="mb-2" /> -->
  </Layout1>
</template>

<script>
/* import UserTable from '@/components/examples/UserTable' */
import Layout1 from '@/components/ui/application/layout/Layout1' 
//import AppCard1Starter from '@/components/ui/application/appcard/AppCard1Starter'
//import AppCard2Starter from '@/components/ui/application/appcard/AppCard2Starter'/*
//import AppCard3Starter from '@/components/ui/application/appcard/AppCard3Starter'
//import AppCard6Starter from '@/components/ui/application/appcard/AppCard6Starter'

export default {
  components: {
    /* UserTable, */
    Layout1
    //AppCard2Starter,
    /*AppCard1Starter ,
    AppCard3Starter,
    AppCard6Starter */
  },
  data () {
    return {
      dialog: false,
      desserts: [
        {
          name: 'Processing Fee',
          fees: 'N300,000.00'
        },
        {
          name: 'Registration Fee',
          fees: '	N1,000,000.00'
        },
        {
          name: 'Renewal Fee',
          fees: 'N500,000.00'
        },
        {
          name: 'Late Application for renewal fee',
          fees: 'N150,000.00'
        },
        {
          name: 'Inspection fee per metric ton',
          fees: 'N100.00'
        },
        {
          name: 'Late submission of tonnage report fee',
          fees: 'N300,000.00'
        }
      ],
      checkboxValue: 0,
      ownershipStatus: 0,
      ownershipStatuses:[
        {
          'id': 1,
          'name': 'Lease'
        },
        {
          'id': 2,
          'name': 'Owner'
        },
        {
          'id': 3,
          'name': 'Rent'
        }
      ],
      headers: [
        {
          text: 'Transaction ID',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Invoice Number', value: 'fees' },
        { text: 'Amount', value: 'fees' },
        { text: 'Line of Business', value: 'fees' },
        { text: 'Description', value: 'fees' },
        { text: 'Payment Status', value: 'fees' },
        { text: 'Payment Channel', value: 'fees' }
      ]
    }
  },
  watch: {
    stateId: {
      handler(newvalue) {
        console.log('item changed', newvalue)
        //////console.log('state id is ', this.stateId)
        this.statelgas = this.alllga.filter((lga) => lga.state_id === this.stateId.id)
        //////console.log('all the lga is ', this.statelgas)
      },
      immediate: true
    }
  },
  methods: {
    
  }
}
</script>

<style scoped>
.card-header-text{
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
.d-card-height{
  min-height: 300px !important;
}
</style>
