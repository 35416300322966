<template>
  <div>
    <ApplyForPermitStarter />
  </div>
</template>

<script>
import ApplyForPermitStarter from '@/components/ui/dashboard/ApplyForPermitStarter.vue'
export default {
  components: {
    ApplyForPermitStarter
  }
}
</script>