<template>
  <v-sheet color="">
    <SignIn1 />

  </v-sheet>
</template>

<script>
import SignIn1 from '@/components/ui/landing/signin/SignIn1.vue'

export default {
  components: {
    SignIn1
  }
}
</script>