<template>
  <CompanyProfile />
</template>

<script>
import CompanyProfile from '@/components/ui/companyprofile/CompanyProfile.vue'

export default {
  components: {
    CompanyProfile
  }
}
</script>