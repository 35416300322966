<template>
  <div>
    <SignUp1Demo />
  </div>

</template>

<script>
import SignUp1Demo from '@/components/ui/landing/signup/SignUp1Demo.vue'
export default {
  components: {
    SignUp1Demo
  }
}
</script>