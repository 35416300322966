<template>
  <div>
    <CompanyProfileStarter />
  </div>
</template>

<script>
import CompanyProfileStarter from '@/components/ui/companyprofile/CompanyProfileStarter.vue'
export default {
  components: {
    CompanyProfileStarter
  }
}
</script>