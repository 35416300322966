<template>
  <Representatives />
</template>

<script>
import Representatives from '@/components/ui/representatives/Representatives.vue'

export default {
  components: {
    Representatives
  }
}
</script>
