<template>
  <v-sheet color="">
    <Toolbar2 />
    <SignUp1 />
  </v-sheet>
</template>

<script>
import SignUp1 from '@/components/ui/landing/signup/SignUp1.vue'
import Toolbar2 from '@/components/ui/landing/toolbar/Toolbar2.vue'

export default {
  components: {
    SignUp1,
    Toolbar2
  }
}
</script>
