<template>
  <v-card style="min-height: 260px;">
    <div class="pa-3">
      <div class="d-flex align-center">
        <div>
          <span class="font-weight-bold text-body-2 primary--text">{{ name }}</span>
          <v-spacer></v-spacer>
          <div class="text-caption">
            {{ title }}
          </div>
          <div> 
            <h1>200</h1><span style="font-size: 12px !important">{{  role }}</span>
            <!-- <v-chip v-if="role" @click="ViewAsset(link)" class="primary font-weight-bold ml-1">Update</v-chip> -->
          </div>
        </div>
      </div>
    </div>
    <v-card-actions>
      <v-btn
        color="primary"
        
      >
        Update
      </v-btn>
    </v-card-actions>

    <!-- <div class="d-flex">
      <v-btn
        v-if="phone"
        class="flex-grow-1"
        tile
        height="48"
        text
        :href="phone"
      >
        <v-icon left>mdi-phone-outline</v-icon>
        Call
      </v-btn>
      <v-divider v-if="phone && email" vertical></v-divider>
      <v-btn
        v-if="email"
        class="flex-grow-1"
        tile
        height="48"
        text
        :href="email"
      >
        <v-icon left>mdi-email-outline</v-icon>
        Email
      </v-btn>
    </div> -->
  </v-card>
</template>

<script>
import router from '../../../../router'

export default {
  props: {
    image: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    role: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    phone: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  methods: {
    ViewAsset: (link) => {
      router.push({ path: link })
      console.log('the console is ', link)
    }
  }
}
</script>
