<template>
  <div>
    <DashboardStarter />
  </div>
</template>

<script>
import DashboardStarter from '@/components/ui/dashboard/DashboardStarter.vue'
export default {
  components: {
    DashboardStarter
  }
}
</script>