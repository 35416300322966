<template>
  <v-container fluid>
    <v-row dense>
      <v-col v-for="(card, index) in cards" :key="index" cols="12" md="3">
        <!-- application card -->
        <AssetsCard v-bind="card" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AssetsCard from '@/components/ui/application/appcard/AssetsCard.vue'

export default {
  components: {
    AssetsCard
  },
  data() {
    return {
      cards: [{
        image: 'mdi-warehouse',
        name: 'Warehouses/Stores/Storage Facilities Summary',
        role: 'Warehouses',
        title: 'Update your warehouses information here',
        phone: '#',
        email: '#',
        link: '/warehouses'
      }, {
        image: 'mdi-clipboard-account',
        name: 'Representative Summary',
        role: 'Representatives',
        title: 'Update your representatives/staff here',
        phone: '',
        email: '#',
        link: '/representatives'
      }, {
        image: 'mdi-information',
        name: 'Fertilizer Products Summary',
        role: 'Products (produced/imported)',
        title: 'Update the fertilizer products which you are producing/importing here',
        phone: '#',
        email: '#',
        link: '/products'
      }, {
        image: 'mdi-information',
        name: 'Fertilizer Packages Summary',
        role: 'Packages',
        title: 'Update the weight or volume in which you package your fertilizer here',
        phone: '#',
        email: '#',
        link: '/packages'
      }]
    }
  }
}
</script>
