<template>
  <Layout1 is-demo class="white">
    <div class="pb-5">
      <h1 class="text-h4">Business Profile</h1>
    </div>
    <v-row>
      <v-col cols="12" sm="12" md="6"  class="py-0">
        <v-menu
          v-model="menu2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Date Of Business Commencement*"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            @input="menu2 = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col md="6" class="py-0">
        <v-text-field label="Current Annual Operational Capacity(MT)*" type="number"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" class="py-0">
        <v-select
          return-object
          v-model="associationWith"
          :items="associations"
          item-text="name"
          item-value="id"
          label="Association Registered With"
          required
        ></v-select>
      </v-col>
      <v-col md="4" class="py-0">
        <v-text-field label="Association Registration Number" type="number"></v-text-field>
      </v-col>
      <v-col md="4" class="py-0">
        <v-menu
          v-model="menu2date"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateWithAssociation"
              label="Date Of Registration With Association"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateWithAssociation"
            @input="menu2date = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="12" class="pb-0">
        <h4>Fertilizer products you will produce/import</h4>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>

    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
        <v-checkbox
          v-model="ex4"
          label="Ammonium Sulfate"
          value="1"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="ex4"
          label="Urea Super Granulated "
          value="2"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
      <v-checkbox
        v-model="ex4"
        label="Ammonium Sulfate"
        value="1"
        hide-details
      ></v-checkbox>
      <v-checkbox
        v-model="ex4"
        label="Urea Super Granulated "
        value="2"
        hide-details
      ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
        <v-checkbox
          v-model="ex4"
          label="Ammonium Sulfate"
          value="1"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="ex4"
          label="Urea Super Granulated "
          value="2"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
        <v-checkbox
          v-model="ex4"
          label="Ammonium Sulfate"
          value="1"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="ex4"
          label="Urea Super Granulated "
          value="2"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
      <v-checkbox
        v-model="ex4"
        label="Ammonium Sulfate"
        value="1"
        hide-details
      ></v-checkbox>
      <v-checkbox
        v-model="ex4"
        label="Urea Super Granulated "
        value="2"
        hide-details
      ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
        <v-checkbox
          v-model="ex4"
          label="Ammonium Sulfate"
          value="1"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="ex4"
          label="Urea Super Granulated "
          value="2"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
        <v-checkbox
          v-model="ex4"
          label="Ammonium Sulfate"
          value="1"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="ex4"
          label="Urea Super Granulated "
          value="2"
          hide-details
        ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
      <v-checkbox
        v-model="ex4"
        label="Ammonium Sulfate"
        value="1"
        hide-details
      ></v-checkbox>
      <v-checkbox
        v-model="ex4"
        label="Urea Super Granulated "
        value="2"
        hide-details
      ></v-checkbox>
      </v-col>
      <v-col
        cols="12"
        sm="4"
        md="4"
        class="pt-0"
      >
        <v-checkbox
          v-model="ex4"
          label="Ammonium Sulfate"
          value="1"
          hide-details
        ></v-checkbox>
        <v-checkbox
          v-model="ex4"
          label="Urea Super Granulated "
          value="2"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn class="success" @click="submitSignin">Update Profile</v-btn>
      </v-col>
    </v-row>
    <!-- <AppCard1Starter class="pa-0 py-2" /> -->
    <!-- <AppCard2Starter class="pa-0 py-2" /> -->
    
    <!-- <AppCard3Starter class="pa-0 mb-1" />
    <AppCard6Starter class="pa-0 mb-2" /> -->
    <!-- <UserTable class="mb-2" /> -->
  </Layout1>
</template>

<script>
/* import UserTable from '@/components/examples/UserTable' */
import Layout1 from '@/components/ui/application/layout/Layout1' 
//import AppCard1Starter from '@/components/ui/application/appcard/AppCard1Starter'
//import AppCard2Starter from '@/components/ui/application/appcard/AppCard2Starter'/*
//import AppCard3Starter from '@/components/ui/application/appcard/AppCard3Starter'
//import AppCard6Starter from '@/components/ui/application/appcard/AppCard6Starter'

export default {
  components: {
    /* UserTable, */
    Layout1
    //AppCard2Starter,
    //AppCard1Starter ,
    /*AppCard3Starter,
    AppCard6Starter */
  },
  data () {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,
      associationWith: 0,
      dateWithAssociation: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2date: false,
      ex4: '',
      desserts: [
        {
          name: 'Frozen Yogurt',
          fees: 159
        },
        {
          name: 'Donut',
          fees: 452
        },
        {
          name: 'KitKat',
          fees: 518
        }
      ],
      headers: [
        {
          text: 'Payment Type',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Fees', value: 'fees' }
      ],
      associations:[
        {
          'id': 1,
          'name': 'FEPSAN'
        },
        {
          'id': 2,
          'name': 'OFPSAN'
        },
        {
          'id': 3,
          'name': 'NAIDA'
        },
        {
          'id': 4,
          'name': 'Others [Add New]'
        },
        {
          'id': 5,
          'name': 'None of the Above'
        }
      ]
    }
  }
}
</script>

<style scoped>
.card-header-text{
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
</style>
