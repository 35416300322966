<template>
  <div>
    <MakePaymentStarter />
  </div>
</template>

<script>
import MakePaymentStarter from '@/components/ui/makepayment/MakePaymentStarter.vue'
export default {
  components: {
    MakePaymentStarter
  }
}
</script>