<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12">
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-alert"
        >
          To generate an invoice make sure you follow the guideline on the Dashboard page
        </v-alert>
      </v-col>
      <v-col v-for="(card, index) in cards" :key="index" cols="12" md="4">
        <!-- application card -->
        <GenerateInvoiceCard v-bind="card" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GenerateInvoiceCard from '@/components/ui/application/appcard/GenerateInvoiceCard.vue'

export default {
  components: {
    GenerateInvoiceCard
  },
  data() {
    return {
      cards: [{
        image: 'mdi-warehouse',
        name: 'Processing Fee Invoice',
        businessline: 'Fertilizer Blender',
        description: 'Processing Fee',
        category: 'N/A',
        currency: 'NGN',
        amount: '300000',
        generateinvoice: false
      }, {
        image: 'mdi-clipboard-account',
        name: 'Registration Fee Invoice',
        businessline: 'Fertilizer Blender',
        description: 'Registration Fee',
        category: 'N/A',
        currency: 'NGN',
        amount: '1000000',
        generateinvoice: true
      }, {
        image: 'mdi-information',
        name: 'Field/Product Evaluation Fee Invoice',
        businessline: '',
        description: '',
        category: '',
        currency: '',
        amount: '',
        generateinvoice: false
      }]
    }
  }
}
</script>
