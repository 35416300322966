<template>
  <div>
    <AssetsAndProductsStarter />
  </div>
</template>

<script>
import AssetsAndProductsStarter from '@/components/ui/assetsandproducts/AssetsAndProductsStarter.vue'
export default {
  components: {
    AssetsAndProductsStarter
  }
}
</script>