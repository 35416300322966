<template>
  <div>
    <RepresentativesStarter />
  </div>
</template>

<script>
import RepresentativesStarter from '@/components/ui/representatives/RepresentativesStarter.vue'
export default {
  components: {
    RepresentativesStarter
  }
}
</script>