export const menu1 = [{
  title: 'Solutions',
  link: '#solutions'
}, {
  title: 'Pricing',
  link: '#pricing'
}, {
  title: 'Docs',
  link: '#docs'
}, {
  title: 'More',
  link: '#more'
}]

export const menu2 = [{
  title: 'Home',
  link: '/home'
},
{
  title: 'About',
  link: '/about'
}
]

export default {
  menu1,
  menu2
}
