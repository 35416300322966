<template>
  <div>
    <TransactionhistoryStarter />
  </div>
</template>

<script>
import TransactionhistoryStarter from '@/components/ui/transactionhistory/TransactionhistoryStarter.vue'
export default {
  components: {
    TransactionhistoryStarter
  }
}
</script>