<template>
  <div>
    <SignIn1Demo />
  </div>
</template>

<script>
import SignIn1Demo from '@/components/ui/landing/signin/SignIn1Demo.vue'
export default {
  components: {
    SignIn1Demo
  }
}
</script>