<template>
  <MakePayment />
</template>

<script>
import MakePayment from '@/components/ui/makepayment/MakePayment.vue'

export default {
  components: {
    MakePayment
  }
}
</script>