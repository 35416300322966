<template>
  <Warehouses />
</template>

<script>
import Warehouses from '@/components/ui/warehouses/Warehouses.vue'

export default {
  components: {
    Warehouses
  }
}
</script>
