<template>
  <Layout1 is-demo class="white">
    <div class="pb-5">
      <h1 class="text-h4">Make Payment</h1>
    </div>
    <v-row>
      <v-col cols="12">
        <v-alert
          text
          dense
          color="teal"
          icon="mdi-alert"
        >
          If you want to make payment, make sure you have generated an invoice at the Generate Invoice page then select the invoice number from the list below. On selection all the invoice details will be populated. Payment can only be done against a new invoice number and you can check the status of an invoice number on the Transaction History page. Due to the new directive from the goverment the only payment gateway is REMITA.
          To pay through Remita; select RRR Generation from the Payment methods option and click Generate RRR, then either to go your email or use the Click here to continue to payment to pay using your ATM card
        </v-alert>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="py-0">
        <v-select
          return-object
          v-model="associationWith"
          :items="allInvoices"
          item-text="name"
          item-value="id"
          label="Select an invoice number"
          required
        ></v-select>
      </v-col>
      <v-col md="6" class="py-0">
        <v-select
          return-object
          v-model="associationWith"
          :items="paymentMethods"
          item-text="name"
          item-value="id"
          label="Select Payment method"
          required
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4" class="py-0">
        <v-text-field label="Transaction ID *"></v-text-field>
      </v-col>
      <v-col md="4" class="py-0">
        <v-text-field label="Amount *"></v-text-field>
      </v-col>
      <v-col md="4" class="py-0">
        <v-text-field label="Email *"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6" class="py-0">
        <v-text-field label="Company Name *"></v-text-field>
      </v-col>
      <v-col md="6" class="py-0">
        <v-text-field label="Description *"></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-btn class="success" @click="submitSignin">Make Payment</v-btn>
      </v-col>
    </v-row>
    <!-- <AppCard1Starter class="pa-0 py-2" /> -->
    <!-- <AppCard2Starter class="pa-0 py-2" /> -->
    
    <!-- <AppCard3Starter class="pa-0 mb-1" />
    <AppCard6Starter class="pa-0 mb-2" /> -->
    <!-- <UserTable class="mb-2" /> -->
  </Layout1>
</template>

<script>
/* import UserTable from '@/components/examples/UserTable' */
import Layout1 from '@/components/ui/application/layout/Layout1' 
//import AppCard1Starter from '@/components/ui/application/appcard/AppCard1Starter'
//import AppCard2Starter from '@/components/ui/application/appcard/AppCard2Starter'/*
//import AppCard3Starter from '@/components/ui/application/appcard/AppCard3Starter'
//import AppCard6Starter from '@/components/ui/application/appcard/AppCard6Starter'

export default {
  components: {
    /* UserTable, */
    Layout1
    //AppCard2Starter,
    //AppCard1Starter ,
    /*AppCard3Starter,
    AppCard6Starter */
  },
  data () {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2: false,
      associationWith: 0,
      dateWithAssociation: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu2date: false,
      ex4: '',
      desserts: [
        {
          name: 'Frozen Yogurt',
          fees: 159
        },
        {
          name: 'Donut',
          fees: 452
        },
        {
          name: 'KitKat',
          fees: 518
        }
      ],
      headers: [
        {
          text: 'Payment Type',
          align: 'start',
          sortable: true,
          value: 'name'
        },
        { text: 'Fees', value: 'fees' }
      ],
      allInvoices:[
        {
          'id': 1,
          'name': '72204-FMP1000020'
        },
        {
          'id': 2,
          'name': '72204-FMP1000021'
        }
      ],
      paymentMethods:[
        {
          'id': 1,
          'name': 'RRR Generation(REMITA)'
        }
      ]
    }
  }
}
</script>

<style scoped>
.card-header-text{
  font-weight: 400 !important;
  color: rgba(0, 0, 0, 0.87);
  padding-bottom: 20px;
}
</style>
